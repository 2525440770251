<template>
  <div class="yard-body">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar-Detail">
          <div class="list-menu">
            <CRow>
                <CCol sm="12">
                  <img :src="imageSrc(this.yardData?.YardProfileRoute?? '')"  width="50px" height="50px" class="img bg-wrap text-center"  />
                </CCol>
            </CRow>
          </div>
          <div class="p-3">
            <ul class="list-unstyled components mb-5">
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="10">
                      <h5 class="text-white mt-0">
                        <b>{{this.yardData?.YardName?? ''}}</b>                                                   
                      </h5>
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.serialNumber')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.YardAlias?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('agridTable.area')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.SquareMeters ?? '' }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.yardType')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.$i18n.locale=='en' ? this.yardData?.TpYardNameEn ?? '' : this.yardData?.TpYardNameEs ?? '' }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.teus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.TeusCapacity ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.occupiedTeus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.TeusOccupied ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li> 
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.availableTeus')}}  
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.TeusAvailable ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.containersEmpty')}}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.YardGpoName ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.todayEntry')}}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.TodayReception ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.todayExit')}}
                    </CCol>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{this.yardData?.TodayDispatch ?? ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
            </ul>
          </div>
      </nav>
            
      <!-- Page Content  -->
        
      <div id="content">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light"
          :class="{'no-margin': null}"
        >
          <div class="container-fluid">
            <button type="button"  id="sidebarCollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
              <CIcon  :name="checkIconToggle"/>
              <span class="sr-only">{{checkTextToggle}}</span>
            </button>
            <div class="collapse navbar-collapse">
              <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                <li v-on:click="closeCollapse()" class="nav-item d-flex align-items-center justify-content-end">
                  <CIcon name="x"/>
                </li>
              </ul>
            </div>
          </div>
        </nav>
          <!-- Contenido Interno -->
        <div class="container-fluid contenido-especial">
            <CRow>
              <CCol sm="12">
                <equipmentDetailRegister :Active='this.reception' :ContainerItem = 'this.dataContainer'></equipmentDetailRegister>
              </CCol>
            </CRow>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import equipmentDetailRegister from './movements-list/reception/equipment-detail-register';

  //data
  function data() {
    return {
      reception: true,
      collapsed: false,
    }
  }

  //mounted
  function mounted(){
        document.getElementById("sidebarCollapseSidebar").onclick = function() {
            document.getElementById("sidebar-Detail").classList.toggle('active');
        }
    }

  //methods

  function closeCollapse(){
    this.$store.state.yardManagement.yardCollapse = 0;
    this.$store.state.yardManagement.yardData = {};
  }

  function checkSideBarToggle(){
    this.collapsed = !this.collapsed;
  }

  function imageSrc(Src){
      if(Src === ''|| !Src){
          return this.srcError;
      }
      else{
          return `${this.$store.getters["connection/getBase"]}${Src}`;
      }
  }

  //computed


  function checkIconToggle() {
      if (this.collapsed) {
          return "cil-chevron-right-alt";
      } else {
          return "cil-chevron-left-alt";
      }
  }
    
  function checkTextToggle() {
      if (this.collapsed) {
          return this.$t("label.close");
      } else {
          return this.$t("label.watch");
      }
  }

 
  export default{
      name: 'yard-reception-detail',
      data,
      mixins: [GeneralMixin],
      components: {
        equipmentDetailRegister,
      },
      mixins: [GeneralMixin],
      beforeDestroy(){
        this.closeCollapse();
      },
      methods: {
        closeCollapse,
        checkSideBarToggle,
        imageSrc,
        
      },
      mounted:mounted,
      computed:{
          checkTextToggle,
          checkIconToggle,
          ...mapState({
              yardData: state => state.yardManagement.yardData,
              yardCollapse: state => state.yardManagement.yardCollapse,
              dataContainer: state => state.containerSearch.dataContainer,
          })
      },
  }
</script>
<style lang="scss">
    @import 'css/style.scss';

    .navWidth{
      width: 15%;
    }
</style>